import React, { useState } from "react";

function PricingTable(props) {
  const { plans, header, lead } = props;
  const [currentBillingPeriod, setCurrentBillingPeriod] = useState("monthly");

  return (
    <section className="slice bg-gradient-dark">
      <div className="container">
        <div className="mb-5 text-center text-white">
          <h1 className="text-white">{header}</h1>
          <h2 className="fluid-paragraph mt-3 text-white">
            <p className="lead lh-180">{lead}</p>
          </h2>
        </div>
        <div className="pricing-container">
          <div className="text-center mb-5">
            <div className="btn-group" role="group" aria-label="Pricing">
              {currentBillingPeriod === "monthly" ? (
                <button
                  type="button"
                  onClick={() => setCurrentBillingPeriod("monthly")}
                  className="btn btn-secondary active"
                >
                  Monthly
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setCurrentBillingPeriod("monthly")}
                  className="btn btn-secondary"
                >
                  Monthly
                </button>
              )}

              {currentBillingPeriod === "yearly" ? (
                <button
                  type="button"
                  onClick={() => setCurrentBillingPeriod("yearly")}
                  className="btn btn-secondary active"
                >
                  Yearly
                  <span className="badge badge-primary badge-pill badge-floating">
                    OFFER
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setCurrentBillingPeriod("yearly")}
                  className="btn btn-secondary"
                >
                  Yearly
                  <span className="badge badge-primary badge-pill badge-floating">
                    OFFER
                  </span>
                </button>
              )}
            </div>
          </div>
          <div className="row gx-4">
            {plans.map((plan) => (
              <div key={plan.name} className="col-lg mb-2">
                <div className="card">
                  {plan.recommended === true ? (
                    <span className="h6 w-60 mx-auto px-4 py-1 rounded-bottom bg-primary text-white">
                      POPULAR
                    </span>
                  ) : null}
                  <div className="card-header delimiter-bottom p-4">
                    <span className="d-block h5 mb-4">{plan.name}</span>
                    <div className="h3 text-primary mb-0">
                      <span className="price">
                        {currentBillingPeriod === "monthly"
                          ? plan.price_monthly
                          : plan.price_annual}
                      </span>
                      <span className="h6 ml-2">
                        {currentBillingPeriod === "monthly" ? "monthly" : "yearly"}
                      </span>
                    </div>
                    <a
                      href={
                        currentBillingPeriod === "monthly"
                          ? plan.pricing_url_monthly
                          : plan.pricing_url_annual
                      }
                      className="btn w-100 btn-primary mt-4 mb-2"
                    >
                      Choose this plan
                    </a>
                  </div>
                  <div className="card-body">
                    <ul className="list-unstyled">
                      {plan.entitlements.map((entitlement) => (
                        <li key={entitlement + plan.name} className="d-flex">
                          <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                            <i className="fas fa-check text-success" />
                          </div>
                          {entitlement}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingTable;
