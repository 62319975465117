import React from "react";

function NewLandedCostCalculatorFormHeader(props) {
  const { creditsUsed, creditsRemaining } = props;

  return (
    <section className="header bg-primary d-flex align-items-end bg-gradient-dark">
      <div className="container my-4">
        <div className="row">
          <div className="col-12">
            <div className="row align-items-center">
              <div className="col-md-5 mb-4 mb-md-0">
                <span className="h2 mb-0 text-white d-block">
                  Landed Cost Calculator
                </span>
                <span className="text-primary">New Calculation</span>
              </div>
              <div className="col-auto flex-fill d-flex d-xl-block">
                <ul className="list-inline row justify-content-lg-end mb-0">
                  <li className="list-inline-item col-sm-6 col-md-auto px-3 my-2 mx-0">
                    <span className="badge badge-dot text-white">
                      <i className="bg-success" />
                      Calculations made
                    </span>
                    <span className="d-sm-block h5 text-white font-weight-bold pl-2 text-right">
                      {creditsUsed}
                    </span>
                  </li>
                  <li className="list-inline-item col-sm-6 col-md-auto px-3 my-2 mx-0">
                    <span className="badge badge-dot text-white">
                      <i className="bg-info" />
                      Calculations left
                    </span>
                    <span className="d-sm-block h5 text-white font-weight-bold pl-2 text-right">
                      {creditsRemaining}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewLandedCostCalculatorFormHeader;
