import React, { useState, useCallback, useEffect } from "react";
import { getSessionToken } from "@shopify/app-bridge-utils";
import {
  AppProvider,
  Page,
  Card,
  TextContainer,
  FormLayout,
  TextField,
  Button,
  SkeletonBodyText,
  SkeletonPage,
  Layout,
  SkeletonDisplayText,
} from "@shopify/polaris";

function LoadingState() {
  return (
    <SkeletonPage primaryAction>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <SkeletonBodyText />
          </Card>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
}

function ShopifyEmbed() {
  const [isLoading, setIsLoading] = useState(true);
  const [connected, setConnected] = useState(false);
  const [sessionToken, setSessionToken] = useState("");
  const [apiToken, setApiToken] = useState("");

  useEffect(() => {
    getSessionToken(window.app).then((token) => setSessionToken(token));
  }, [sessionToken]);

  // initial state sync with server
  useEffect(() => {
    if (sessionToken === "") {
      return;
    }
    const headers = new Headers({ Authorization: `Bearer ${sessionToken}` });

    fetch("/shopify/dutify", { headers, method: "GET" })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setConnected(data.is_linked);
      });
  }, [sessionToken]);

  const handleApiTokenchange = useCallback((value) => setApiToken(value), []);

  const handleToggleDutifyConnection = useCallback(() => {
    const headers = new Headers({
      Authorization: `Bearer ${sessionToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    setIsLoading(true);
    if (connected) {
      return fetch("/shopify/dutify", {
        headers,
        method: "DELETE",
      })
        .then((response) => response.json())
        .then((data) => {
          setConnected(data.is_linked);
          setIsLoading(false);
        });
    }
    return fetch("/shopify/dutify", {
      method: "POST",
      headers,
      body: JSON.stringify({ apiToken }),
    })
      .then((response) => response.json())
      .then((data) => {
        setConnected(data.is_linked);
        setIsLoading(false);
      });
  }, [connected, sessionToken, apiToken]);

  return (
    <AppProvider>
      {isLoading ? (
        <LoadingState />
      ) : (
        <Page>
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <img
                  src="https://dutify.com/logo.png"
                  alt="dutify logo"
                  className="Polaris-CalloutCard__Image"
                />
              </Card>
              {connected ? (
                <Card sectioned>
                  <Button
                    size="large"
                    destructive
                    onClick={handleToggleDutifyConnection}
                  >
                    Disable Dutify integration
                  </Button>
                </Card>
              ) : (
                <Card sectioned>
                  <Card.Section>
                    <TextContainer>
                      You can use the section below to manage your Dutify
                      connection.
                    </TextContainer>
                  </Card.Section>
                  <Card.Section>
                    <FormLayout>
                      <TextField
                        value={apiToken}
                        onChange={handleApiTokenchange}
                        label="Dutify Api Token"
                        type="text"
                        helpText={
                          <span>
                            We will use this valid API Token to link your Dutify
                            account.
                          </span>
                        }
                      />

                      <Button
                        size="large"
                        primary
                        onClick={handleToggleDutifyConnection}
                      >
                        Connect
                      </Button>
                    </FormLayout>
                  </Card.Section>
                </Card>
              )}
            </Layout.Section>
          </Layout>
        </Page>
      )}
    </AppProvider>
  );
}

export default ShopifyEmbed;
