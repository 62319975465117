import React, { useState } from "react";
import CountrySelect from "./CountrySelect";

function CountryList(props) {
  const { countries } = props;

  const [countryList, setCountryList] = useState(props.countries);

  const handleEnabledAll = (e) => {
    e.preventDefault();
    setCountryList(
      countryList.map((currentValue) => ({ ...currentValue, enabled: true }))
    );
  };

  const handleDisableAll = (e) => {
    e.preventDefault();
    setCountryList(
      countryList.map((currentValue) => ({ ...currentValue, enabled: false }))
    );
  };

  const toggleCountry = (id) => {
    setCountryList(
      countryList.map((currentValue) => ({
        ...currentValue,
        enabled:
          currentValue.id === id ? !currentValue.enabled : currentValue.enabled,
      }))
    );
  };

  return (
    <div className="overflow-auto" style={{ maxHeight: "300px" }}>
      <a href="#" onClick={handleEnabledAll}>
        Select all
      </a>{" "}
      /
      <a href="#" onClick={handleDisableAll}>
        Disable all
      </a>
      {countryList.map((country) => (
        <CountrySelect
          key={country.id}
          {...country}
          toggleCountry={toggleCountry}
        />
      ))}
    </div>
  );
}

export default CountryList;
