import React from "react";
import PropTypes from "prop-types";

class ProductClassificationSelect extends React.Component {
  constructor(props) {
    super(props);

    const { classificationTree } = this.props;
    this.state = { classificationTree };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const selectedValue = event.target.value;
    const { classificationTree } = this.state;
    classificationTree.selectedValue = selectedValue;

    const selectedNode = classificationTree.selectOptions[selectedValue];
    const isLeafSelected = !!(
      selectedNode && Object.keys(selectedNode.selectOptions).length === 0
    );

    if (isLeafSelected) {
      this.props.handleLeafSelected(selectedValue);
    }

    this.setState((previousState) => ({
      ...previousState,
      classificationTree,
    }));
  }

  renderOptions() {
    const {
      classificationTree: { selectOptions },
    } = this.props;

    return Object.values(selectOptions)
      .sort((firstItem, secondItem) =>
        firstItem.label.localeCompare(secondItem.label)
      )
      .map(({ id, label }) => (
        <option key={id} value={id}>
          {label}
        </option>
      ));
  }

  render() {
    const { railsFormName, leafIndex } = this.props;
    const { classificationTree } = this.state;
    const selectFieldName = `${railsFormName}[product_classification_ids][]`;
    const selectedValue = classificationTree.selectedValue || "";
    const selectedLeaf = classificationTree.selectOptions[selectedValue];
    const shouldRenderNextDropdown = !!(
      selectedLeaf && Object.keys(selectedLeaf.selectOptions).length > 0
    );

    let additionalClassName = "";

    if (!selectedLeaf) {
      additionalClassName = "form-control";
    } else {
      additionalClassName = "classified-successfully";
    }

    return (
      <>
        <div className="form-group">
          <select
            name={selectFieldName}
            required
            value={selectedValue}
            onChange={this.handleChange}
            id={`product-classification-leaf-${leafIndex}`}
            className={`form-control custom-select custom-select-lg ${additionalClassName}`}
          >
            <option value="">Select category</option>
            {this.renderOptions()}
          </select>
          {selectedLeaf && shouldRenderNextDropdown === false && (
            <div className="valid-feedback is-valid">
              Successfully classified!
            </div>
          )}
        </div>
        {shouldRenderNextDropdown && (
          <ProductClassificationSelect
            key={selectedValue}
            classificationTree={selectedLeaf}
            leafIndex={leafIndex + 1}
            railsFormName={railsFormName}
            handleLeafSelected={this.props.handleLeafSelected}
          />
        )}
      </>
    );
  }
}

ProductClassificationSelect.defaultProps = {
  classificationTree: {},
  leafIndex: 0,
  railsFormName: "",
  handleLeafSelected: () => {},
};

ProductClassificationSelect.propTypes = {
  classificationTree: PropTypes.object,
  leafIndex: PropTypes.number,
  railsFormName: PropTypes.string,
};

export default ProductClassificationSelect;
