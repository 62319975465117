import React, { useState } from "react";
import ProductClassificationSearch from "./ProductClassificationSearch";
import ProductClassificationSelect from "./ProductClassificationSelect";

function LandedCostCalculatorForm(props) {
  const {
    importTypes,
    modesOfTransport,
    currencies,
    leafIndex,
    railsFormName,
    importCountries,
    exportCountries,
  } = props;

  const [selectedLeafId, setSelectedLeafId] = useState("");
  const [classificationTree, setClassificationTree] = useState(
    props.classificationTree || {}
  );
  const [selectedExportCountryCode, setSelectedExportCountryCode] = useState(
    props.selectedExportCountryCode
  );
  const [selectedImportCountryCode, setSelectedImportCountryCode] = useState(
    props.selectedImportCountryCode
  );
  const [itemDescription, setItemDescription] = useState(props.itemDescription);
  const [itemCost, setItemCost] = useState(props.itemCost);
  const [itemQuantity, setItemQuantity] = useState(props.itemQuantity);
  const [itemCostCurrencyCode, setItemCostCurrencyCode] = useState(
    props.itemCostCurrencyCode
  );
  const [itemShippingCost, setItemShippingCost] = useState(
    props.itemShippingCost
  );
  const [itemInsuranceCost, setItemInsuranceCost] = useState(
    props.itemInsuranceCost
  );
  const [selectedModeOfTransport, setSelectedModeOfTransport] = useState(
    props.selectedModeOfTransport
  );
  const [selectedImportType, setSelectedImportType] = useState(
    props.selectedImportType
  );
  const [extraFields, setExtraFields] = useState(props.extraFields);
  const [selectedExtraFields, setSelectedExtraFields] = useState({});

  const handleLeafSelected = (selectedValue) => {
    setSelectedLeafId(selectedValue);

    fetchExtraFields(selectedValue, selectedImportCountryCode);
  };

  const fetchExtraFields = (selectedLeafId, countryCode) => {
    const path = `/api/int/landed_cost_calculator/extra_fields.json?product_classification_id=${selectedLeafId}&import_country_iso=${countryCode}`;

    selectedLeafId &&
      countryCode &&
      fetch(path, { method: "GET" })
        .then((response) => response.json())
        .then((response) => setExtraFields(response.extra_fields));
  };

  const handleCountryChange = (event) => {
    const { value } = event.target;

    setSelectedImportCountryCode(value);

    fetchExtraFields(selectedLeafId, value);
  };

  const handleClassificationChange = (selectedAutoSuggestion) => {
    if (selectedAutoSuggestion.ancestry_ids[0]) {
      classificationTree.selectedValue = selectedAutoSuggestion.ancestry_ids[0];
    }
    if (selectedAutoSuggestion.ancestry_ids[1]) {
      classificationTree.selectOptions[
        selectedAutoSuggestion.ancestry_ids[0]
      ].selectedValue = selectedAutoSuggestion.ancestry_ids[1];
    }

    if (selectedAutoSuggestion.ancestry_ids[2]) {
      classificationTree.selectOptions[
        selectedAutoSuggestion.ancestry_ids[0]
      ].selectOptions[selectedAutoSuggestion.ancestry_ids[1]].selectedValue =
        selectedAutoSuggestion.ancestry_ids[2];
    }

    if (selectedAutoSuggestion.ancestry_ids[3]) {
      classificationTree.selectOptions[
        selectedAutoSuggestion.ancestry_ids[0]
      ].selectOptions[selectedAutoSuggestion.ancestry_ids[1]].selectOptions[
        selectedAutoSuggestion.ancestry_ids[2]
      ].selectedValue = selectedAutoSuggestion.ancestry_ids[3];

      fetchExtraFields(
        selectedAutoSuggestion.ancestry_ids[3],
        selectedImportCountryCode
      );

      setSelectedLeafId(selectedAutoSuggestion.ancestry_ids[3]);
      // setHideInput(true);
      setClassificationTree(classificationTree);
    } else {
      // setHideInput(true);
      setClassificationTree(classificationTree);
    }
  };

  const renderShippingCost = () => (
    <div className="col-md-6 col-sm-12">
      <div className="form-group numeric optional duty_calculator_form_shipping_cost form-group-valid">
        <label
          className="numeric optional form-control-label"
          htmlFor="duty_calculator_form_shipping_cost"
        >
          Shipping cost
        </label>
        <input
          onChange={(e) => setItemShippingCost(e.target.value)}
          value={itemShippingCost}
          className="form-control numeric optional form-control-lg"
          placeholder="0.0"
          type="number"
          min="0.00"
          step="0.01"
          name="duty_calculator_form[shipping_cost]"
        />
      </div>
    </div>
  );

  const renderInsuranceCost = () => (
    <div className="col-md-6 col-sm-12">
      <div className="form-group numeric optional duty_calculator_form_insurance_cost form-group-valid">
        <label
          className="numeric optional form-control-label"
          htmlFor="duty_calculator_form_insurance_cost"
        >
          Insurance cost
        </label>
        <input
          onChange={(e) => setItemInsuranceCost(e.target.value)}
          value={itemInsuranceCost}
          className="form-control numeric optional form-control-lg"
          placeholder="0.0"
          type="number"
          min="0.00"
          step="0.01"
          name="duty_calculator_form[insurance_cost]"
        />
      </div>
    </div>
  );

  const renderModeOfTransport = () => {
    const selectedCountry = importCountries.find(
      (country) => country.isoCode === selectedImportCountryCode
    );

    if (!selectedCountry) {
      return;
    }

    const { requiresModeOfTransport } = selectedCountry;

    if (!requiresModeOfTransport) {
      return;
    }

    return (
      <div className="col-md-6 col-sm-12">
        <label
          className="string optional form-control-label"
          htmlFor="duty_calculator_form_mode_of_transport"
        >
          Mode of transport
        </label>
        <select
          onChange={(e) => setSelectedModeOfTransport(e.target.value)}
          value={selectedModeOfTransport}
          className="form-control custom-select custom-select-lg"
          name="duty_calculator_form[mode_of_transport]"
        >
          {modesOfTransport.map((element) => (
            <option key={element[0]} value={element[0]}>
              {element[1]}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderImportType = () => {
    const selectedCountry = importCountries.find(
      (country) => country.isoCode === selectedImportCountryCode
    );
    if (!selectedCountry) {
      return;
    }

    const { requiresImportType } = selectedCountry;

    if (!requiresImportType) {
      return;
    }

    return (
      <div className="col-md-6 col-sm-12">
        <label
          className="string optional form-control-label"
          htmlFor="duty_calculator_form_mode_of_transport"
        >
          Import type
        </label>
        <select
          onChange={(e) => setSelectedImportType(e.target.value)}
          value={selectedImportType}
          className="form-control custom-select custom-select-lg"
          name="duty_calculator_form[import_type]"
        >
          {importTypes.map((element) => (
            <option key={element[0]} value={element[0]}>
              {element[1]}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderExtraFields = () => {
    if (extraFields && extraFields.length > 0) {
      return (
        <>
          {extraFields.map((field, idx) => (
            <div className="col-md-6 col-sm-12" key={field.unit_name}>
              <div className="form-group numeric form-group-valid">
                <label className="numeric form-control-label">
                  Measurements (in {field.unit_name})
                  <abbr title="required">*</abbr>
                </label>
                <input
                  name={`duty_calculator_form${field.unit_field_name}`}
                  value={field.unit_name}
                  hidden
                  readOnly
                />
                <input
                  id={`measurements_${field.unit_name}`}
                  onChange={(e) =>
                    setSelectedExtraFields({
                      ...selectedExtraFields,
                      [`measurements_${field.unit_name}`]: e.target.value,
                    })
                  }
                  value={extraFields[`measurements_${field.value_field_name}`]}
                  required="required"
                  name={`duty_calculator_form${field.value_field_name}`}
                  className="form-control numeric form-control-lg"
                  placeholder="0.0"
                  type="number"
                  min="0.00"
                  step="0.01"
                />
              </div>
            </div>
          ))}
        </>
      );
    }
  };

  const renderItemCost = () => (
    <>
      <div className="col-md-4 col-sm-12">
        <div className="form-group numeric required duty_calculator_form_item_value">
          <label
            className="numeric required form-control-label"
            htmlFor="duty_calculator_form_item_value"
          >
            Unit price
            <abbr title="required">*</abbr>
          </label>
          <input
            onChange={(e) => setItemCost(e.target.value)}
            value={itemCost}
            className="form-control numeric required form-control-lg"
            required="required"
            aria-required="true"
            placeholder="0.0"
            type="number"
            min="0.01"
            step="0.01"
            name="duty_calculator_form[item_value]"
          />
        </div>
      </div>
      <div className="col-md-4 col-sm-12">
        <div className="form-group select required duty_calculator_form_currency_code form-group-valid">
          <label
            className="select required form-control-label"
            htmlFor="duty_calculator_form_currency_code"
          >
            Currency
            <abbr title="required">*</abbr>
          </label>
          <select
            onChange={(e) => setItemCostCurrencyCode(e.target.value)}
            value={itemCostCurrencyCode}
            className="form-control select required custom-select custom-select-lg"
            required="required"
            aria-required="true"
            name="duty_calculator_form[currency_code]"
          >
            {currencies.map((element) => (
              <option key={element} value={element}>
                {element}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col-md-4 col-sm-12">
        <div className="form-group numeric required duty_calculator_form_item_quantity">
          <label
            className="numeric required form-control-label"
            htmlFor="duty_calculator_form_item_quantity"
          >
            Item quantity
            <abbr title="required">*</abbr>
          </label>
          <input
            onChange={(e) => setItemQuantity(e.target.value)}
            value={itemQuantity}
            className="form-control numeric required form-control-lg"
            required="required"
            aria-required="true"
            placeholder="1"
            type="number"
            min="1"
            step="1"
            name="duty_calculator_form[item_quantity]"
          />
        </div>
      </div>
    </>
  );

  const renderImportCountrySelect = () => (
    <div className="col-md-6 col-sm-12">
      <div className="form-group select required">
        <label
          className="select required form-control-label"
          htmlFor="duty_calculator_form_export_country_code"
        >
          To
          <abbr title="required">*</abbr>
        </label>
        <select
          onChange={handleCountryChange}
          value={selectedImportCountryCode}
          name="duty_calculator_form[import_country_code]"
          className="form-control select required custom-select custom-select-lg"
          required
          aria-required
        >
          {importCountries.map((country) => (
            <option key={country.isoCode} value={country.isoCode}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  const renderExportCountrySelect = () => (
    <div className="col-md-6 col-sm-12">
      <div className="form-group select required">
        <label
          className="select required form-control-label"
          htmlFor="duty_calculator_form_export_country_code"
        >
          From
          <abbr title="required">*</abbr>
        </label>
        <select
          onChange={(e) => setSelectedExportCountryCode(e.target.value)}
          value={selectedExportCountryCode}
          name="duty_calculator_form[export_country_code]"
          className="form-control select required custom-select custom-select-lg"
          required
          aria-required
        >
          {exportCountries.map((country) => (
            <option key={country.isoCode} value={country.isoCode}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  const renderProductClassificationInputs = () => (
    <>
      <label className="form-control-label">
        <small className="form-text">
          By typing your product name below, we'll attempt to automatically
          classify your product. You can always change it or classify manually
          by using the 'Select category' dropdown below.
        </small>
      </label>
      <ProductClassificationSearch
        value={itemDescription}
        handleClassificationChange={handleClassificationChange}
        railsFormName={railsFormName}
      />
      <ProductClassificationSelect
        classificationTree={classificationTree}
        railsFormName={railsFormName}
        leafIndex={leafIndex}
        handleLeafSelected={handleLeafSelected}
      />
    </>
  );

  return (
    <>
      <div className="bg-section-primary delimiter-top delimiter-bottom">
        <div className="container">
          <h6 className="text-uppercase ls-2 font-weight-700">Step 1</h6>
          <p className="lead lh-180 mb-3">Where are you shipping?</p>
          <div className="form-group row">
            {renderExportCountrySelect()}
            {renderImportCountrySelect()}
          </div>
        </div>
      </div>

      <div className="bg-section-secondary delimiter-top delimiter-bottom py-4">
        <div className="container">
          <h6 className="text-uppercase ls-2 font-weight-700">Step 2</h6>
          <p className="lead lh-180 mb-3">
            Tell us what you're shipping
          </p>
          {renderProductClassificationInputs()}
          <div className="form-group row">{renderItemCost()}</div>
        </div>
      </div>

      <div className="bg-section-primary delimiter-top delimiter-bottom pt-4">
        <div className="container">
          <h6 className="text-uppercase ls-2 font-weight-700">Step 3</h6>
          <p className="lead lh-180 mb-3">How are you shipping?</p>
          <div className="form-group row">
            {renderShippingCost()}
            {renderInsuranceCost()}
            {renderModeOfTransport()}
            {renderImportType()}
          </div>
        </div>
        <div className="container">
          <div className="form-group row">{renderExtraFields()}</div>
        </div>
      </div>
    </>
  );
}

export default LandedCostCalculatorForm;
