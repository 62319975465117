import React from "react";
import ProductClassificationSearch from "./ProductClassificationSearch";
import ProductClassificationSelect from "./ProductClassificationSelect";

class NewLandedCostCalculatorForm extends React.Component {
  constructor(props) {
    super(props);

    const { classificationTree } = this.props;

    this.handleClassificationChange =
      this.handleClassificationChange.bind(this);
    this.fetchExtraFields = this.fetchExtraFields.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleLeafSelected = this.handleLeafSelected.bind(this);
    this.toggleAutocomplete = this.toggleAutocomplete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      selectedValue: "",
      productDescription: "",
      hideInput: false,
      classificationTree,

      importCountries: props.importCountries,
      exportCountries: props.exportCountries,

      selectedExportCountryCode: props.selectedExportCountryCode,
      selectedImportCountryCode: props.selectedImportCountryCode,
      itemDescription: props.itemDescription,
      itemCost: props.itemCost || 0.0,
      itemQuantity: props.itemQuantity,
      itemCostCurrencyCode: props.itemCostCurrencyCode,
      itemShippingCost: props.itemShippingCost,
      itemInsuranceCost: props.itemInsuranceCost,
      selectedModeOfTransport: props.selectedModeOfTransport,
      selectedImportType: props.selectedImportType,
      selectedImportStateCode: props.selectedImportStateCode,
      extraFields: props.extraFields,
    };
  }

  handleLeafSelected(selectedValue) {
    this.setState((state) => ({
      ...state,
      selectedLeafId: selectedValue,
    }));

    this.fetchExtraFields(selectedValue, this.state.selectedImportCountryCode);
  }

  handleCountryChange(event) {
    const { target } = event;
    const { value } = target;

    this.setState((previousState) => ({
      ...previousState,
      selectedImportCountryCode: value,
      selectedImportStateCode: "",
    }));

    this.fetchExtraFields(this.state.selectedLeafId, value);
  }

  fetchExtraFields(selectedLeafId, countryCode) {
    if (!selectedLeafId || !countryCode) {
      return;
    }

    fetch(
      `/api/int/landed_cost_calculator/extra_fields.json?product_classification_id=${selectedLeafId}&import_country_iso=${countryCode}`,
      { method: "GET" }
    )
      .then((r) => r.json())
      .then((r) => {
        this.setState((state) => ({
          ...state,
          extraFields: r.extra_fields,
        }));
      });
  }

  handleClassificationChange(selectedAutoSuggestion) {
    const { classificationTree } = this.state;

    if (selectedAutoSuggestion.ancestry_ids[0]) {
      classificationTree.selectedValue = selectedAutoSuggestion.ancestry_ids[0];
    }
    if (selectedAutoSuggestion.ancestry_ids[1]) {
      classificationTree.selectOptions[
        selectedAutoSuggestion.ancestry_ids[0]
      ].selectedValue = selectedAutoSuggestion.ancestry_ids[1];
    }

    if (selectedAutoSuggestion.ancestry_ids[2]) {
      classificationTree.selectOptions[
        selectedAutoSuggestion.ancestry_ids[0]
      ].selectOptions[selectedAutoSuggestion.ancestry_ids[1]].selectedValue =
        selectedAutoSuggestion.ancestry_ids[2];
    }

    if (selectedAutoSuggestion.ancestry_ids[3]) {
      classificationTree.selectOptions[
        selectedAutoSuggestion.ancestry_ids[0]
      ].selectOptions[selectedAutoSuggestion.ancestry_ids[1]].selectOptions[
        selectedAutoSuggestion.ancestry_ids[2]
      ].selectedValue = selectedAutoSuggestion.ancestry_ids[3];

      this.fetchExtraFields(
        selectedAutoSuggestion.ancestry_ids[3],
        this.state.selectedImportCountryCode
      );

      this.setState((previousState) => ({
        ...previousState,
        selectedLeafId: selectedAutoSuggestion.ancestry_ids[3],
        hideInput: true,
        classificationTree,
      }));
    } else {
      this.setState((previousState) => ({
        ...previousState,
        hideInput: true,
        classificationTree,
      }));
    }
  }

  toggleAutocomplete() {
    const { classificationTree } = this.state;

    classificationTree.selectedValue = "";

    this.setState((previousState) => ({
      ...previousState,
      hideInput: false,
      classificationTree,
    }));
  }

  handleInputChange(event, attrName) {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState((previousState) => ({
      ...previousState,
      [attrName]: value,
    }));
  }

  renderShippingCost() {
    return (
      <div className="col-md-6 col-sm-12">
        <div className="form-group numeric optional duty_calculator_form_shipping_cost form-group-valid">
          <label
            className="numeric optional form-control-label"
            htmlFor="duty_calculator_form_shipping_cost"
          >
            Shipping cost
          </label>
          <input
            onChange={(e) => this.handleInputChange(e, "itemShippingCost")}
            value={this.state.itemShippingCost}
            className="form-control numeric optional form-control-lg"
            placeholder="0.0"
            type="number"
            min="0.00"
            step="0.01"
            name="duty_calculator_form[shipping_cost]"
          />
        </div>
      </div>
    );
  }

  renderInsuranceCost() {
    return (
      <div className="col-md-6 col-sm-12">
        <div className="form-group numeric optional duty_calculator_form_insurance_cost form-group-valid">
          <label
            className="numeric optional form-control-label"
            htmlFor="duty_calculator_form_insurance_cost"
          >
            Insurance cost
          </label>
          <input
            onChange={(e) => this.handleInputChange(e, "itemInsuranceCost")}
            value={this.state.itemInsuranceCost}
            className="form-control numeric optional form-control-lg"
            placeholder="0.0"
            type="number"
            min="0.00"
            step="0.01"
            name="duty_calculator_form[insurance_cost]"
          />
        </div>
      </div>
    );
  }

  renderModeOfTransport() {
    const { selectedImportCountryCode, importCountries } = this.state;
    const selectedCountry = importCountries.find(
      (country) => country.isoCode === selectedImportCountryCode
    );
    if (!selectedCountry) {
      return;
    }

    const { requiresModeOfTransport } = selectedCountry;

    if (!requiresModeOfTransport) {
      return;
    }

    return (
      <div className="col-md-6 col-sm-12">
        <label
          className="string optional form-control-label"
          htmlFor="duty_calculator_form_mode_of_transport"
        >
          Mode of transport
        </label>
        <select
          onChange={(e) => this.handleInputChange(e, "selectedModeOfTransport")}
          value={this.state.selectedModeOfTransport}
          className="form-control custom-select custom-select-lg"
          name="duty_calculator_form[mode_of_transport]"
        >
          {this.props.modesOfTransport.map((element) => (
            <option key={element[0]} value={element[0]}>
              {element[1]}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderImportType() {
    const { selectedImportCountryCode, importCountries } = this.state;
    const selectedCountry = importCountries.find(
      (country) => country.isoCode === selectedImportCountryCode
    );
    if (!selectedCountry) {
      return;
    }

    const { requiresImportType } = selectedCountry;

    if (!requiresImportType) {
      return;
    }

    return (
      <div className="col-md-6 col-sm-12">
        <label
          className="string optional form-control-label"
          htmlFor="duty_calculator_form_mode_of_transport"
        >
          Import type
        </label>
        <select
          onChange={(e) => this.handleInputChange(e, "selectedImportType")}
          value={this.state.selectedImportType}
          className="form-control custom-select custom-select-lg"
          name="duty_calculator_form[import_type]"
        >
          {this.props.importTypes.map((element) => (
            <option key={element[0]} value={element[0]}>
              {element[1]}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderStateSelect() {
    const { selectedImportCountryCode, importCountries } = this.state;
    const selectedCountry = importCountries.find(
      (country) => country.isoCode === selectedImportCountryCode
    );

    if (!selectedCountry) {
      return;
    }

    const { requiresStateSelect } = selectedCountry;

    if (!requiresStateSelect) {
      return;
    }

    return (
      <div className="col-12">
        <label
          className="string optional form-control-label"
          htmlFor="duty_calculator_form_mode_of_transport"
        >
          Region
          <abbr title="required">*</abbr>
        </label>
        <select
          onChange={(e) => this.handleInputChange(e, "selectedImportStateCode")}
          value={this.state.selectedImportStateCode}
          className="form-control custom-select custom-select-lg"
          name="duty_calculator_form[import_state_code]"
          required
        >
          <option value="">Select region</option>
          {selectedCountry.subdivisions.map((element) => (
            <option key={element[1]} value={element[1]}>
              {element[0]}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderExtraFields() {
    const { extraFields } = this.state;

    if (extraFields && extraFields.length > 0) {
      return (
        <>
          {extraFields.map((field, idx) => (
            <div className="col-md-6 col-sm-12" key={field.unit_name}>
              <div className="form-group numeric form-group-valid">
                <label className="numeric form-control-label">
                  Measurements (in {field.unit_name})
                  <abbr title="required">*</abbr>
                </label>
                <input
                  name={`duty_calculator_form${field.unit_field_name}`}
                  value={field.unit_name}
                  hidden
                />
                <input
                  id={`measurements_${field.unit_name}`}
                  onChange={(e) =>
                    this.handleInputChange(e, `measurements_${field.unit_name}`)
                  }
                  value={this.state[`measurements_${field.value_field_name}`]}
                  required="required"
                  name={`duty_calculator_form${field.value_field_name}`}
                  className="form-control numeric form-control-lg"
                  placeholder="0.0"
                  type="number"
                  min="0.00"
                  step="0.01"
                />
              </div>
            </div>
          ))}
        </>
      );
    }
  }

  renderItemCost() {
    return (
      <>
        <div className="col-md-4 col-sm-12">
          <div className="form-group numeric required duty_calculator_form_item_value">
            <label
              className="numeric required form-control-label"
              htmlFor="duty_calculator_form_item_value"
            >
              Unit price
              <abbr title="required">*</abbr>
            </label>
            <input
              onChange={(e) => this.handleInputChange(e, "itemCost")}
              value={this.state.itemCost}
              className="form-control numeric required form-control-lg"
              required
              aria-required="true"
              placeholder="0.0"
              type="number"
              min="0.01"
              step="0.01"
              name="duty_calculator_form[item_value]"
            />
          </div>
        </div>
        <div className="col-md-4 col-sm-12">
          <div className="form-group select required duty_calculator_form_currency_code form-group-valid">
            <label
              className="select required form-control-label"
              htmlFor="duty_calculator_form_currency_code"
            >
              Currency
              <abbr title="required">*</abbr>
            </label>
            <select
              onChange={(e) =>
                this.handleInputChange(e, "itemCostCurrencyCode")
              }
              value={this.state.itemCostCurrencyCode}
              className="form-control select required custom-select custom-select-lg"
              required="required"
              aria-required="true"
              name="duty_calculator_form[currency_code]"
            >
              {this.props.currencies.map((element) => (
                <option key={element} value={element}>
                  {element}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-4 col-sm-12">
          <div className="form-group numeric required duty_calculator_form_item_quantity">
            <label
              className="numeric required form-control-label"
              htmlFor="duty_calculator_form_item_quantity"
            >
              Item quantity
              <abbr title="required">*</abbr>
            </label>
            <input
              onChange={(e) => this.handleInputChange(e, "itemQuantity")}
              value={this.state.itemQuantity}
              className="form-control numeric required form-control-lg"
              required="required"
              aria-required="true"
              placeholder="1"
              type="number"
              min="1"
              step="1"
              name="duty_calculator_form[item_quantity]"
            />
          </div>
        </div>
      </>
    );
  }

  renderImportCountrySelect() {
    return (
      <div className="col-md-6 col-sm-12">
        <div className="form-group select required">
          <label
            className="select required form-control-label"
            htmlFor="duty_calculator_form_export_country_code"
          >
            To
            <abbr title="required">*</abbr>
          </label>
          <select
            onChange={this.handleCountryChange}
            value={this.state.selectedImportCountryCode}
            name="duty_calculator_form[import_country_code]"
            className="form-control select required custom-select custom-select-lg"
            required
            aria-required
          >
            {this.state.importCountries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  renderExportCountrySelect() {
    return (
      <div className="col-md-6 col-sm-12">
        <div className="form-group select required">
          <label
            className="select required form-control-label"
            htmlFor="duty_calculator_form_export_country_code"
          >
            From
            <abbr title="required">*</abbr>
          </label>
          <select
            onChange={(e) =>
              this.handleInputChange(e, "selectedExportCountryCode")
            }
            value={this.state.selectedExportCountryCode}
            name="duty_calculator_form[export_country_code]"
            className="form-control select required custom-select custom-select-lg"
            required
            aria-required
          >
            {this.state.exportCountries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  renderProductClassificationInputs() {
    return (
      <>
        <label className="form-control-label">
          <small className="form-text text-muted">
            By typing your product name below, we'll attempt to automatically
            classify your product. You can always change it or classify manually
            by using the 'Select category' dropdown below.
          </small>
        </label>
        <ProductClassificationSearch
          value={this.state.itemDescription}
          handleClassificationChange={this.handleClassificationChange}
          railsFormName={this.props.railsFormName}
        />
        <ProductClassificationSelect
          classificationTree={this.state.classificationTree}
          railsFormName={this.props.railsFormName}
          leafIndex={this.props.leafIndex}
          handleLeafSelected={this.handleLeafSelected}
        />
      </>
    );
  }

  render() {
    return (
      <>
        <div className="bg-section-primary delimiter-top delimiter-bottom">
          <div className="container">
            <h6 className="text-uppercase ls-2 font-weight-700">Step 1</h6>
            <p className="lead lh-180 mb-0">Where are you shipping?</p>
            <div className="form-group row mt-5">
              {this.renderExportCountrySelect()}
              {this.renderImportCountrySelect()}
              {this.renderStateSelect()}
            </div>
          </div>
        </div>

        <div className="bg-section-secondary delimiter-top delimiter-bottom py-4">
          <div className="container">
            <h6 className="text-uppercase ls-2 font-weight-700">Step 2</h6>
            <p className="lead lh-180 mb-0">Tell us what you're shipping</p>
            {this.renderProductClassificationInputs()}
            <div className="form-group row mt-5">{this.renderItemCost()}</div>
          </div>
        </div>

        <div className="bg-section-primary delimiter-top delimiter-bottom pt-4">
          <div className="container">
            <h6 className="text-uppercase ls-2 font-weight-700">Step 3</h6>
            <p className="lead lh-180 mb-0">How are you shipping?</p>
            <div className="form-group row">
              {this.renderShippingCost()}
              {this.renderInsuranceCost()}
              {this.renderModeOfTransport()}
              {this.renderImportType()}
            </div>
          </div>
          <div className="container">
            <div className="form-group row">{this.renderExtraFields()}</div>
          </div>
        </div>
      </>
    );
  }
}

NewLandedCostCalculatorForm.defaultProps = {
  classificationTree: {},
  leafIndex: 0,
  railsFormName: "",
};

export default NewLandedCostCalculatorForm;
