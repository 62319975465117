import React from "react";

function CountrySelect(props) {
  const { name, id, enabled, toggleCountry } = props;

  return (
    <div className="custom-control custom-switch">
      <input
        type="checkbox"
        className="custom-control-input"
        name="partner[country_ids][]"
        id={id}
        value={id}
        checked={enabled}
        onChange={() => {
          toggleCountry(id);
        }}
      />
      <label className="custom-control-label" htmlFor={id}>
        {name}
      </label>
    </div>
  );
}

export default CountrySelect;
