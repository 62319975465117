import React, { useState, useCallback } from "react";
import debounce from "lodash/debounce";

function ProductClassificationSearch(props) {
  const INPUT_DELAY = 250;

  const { railsFormName, handleClassificationChange } = props;

  const [searchKeyword, setSearchKeyword] = useState(props.value || "");
  const [autoSuggestions, setAutoSuggestions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showAutoSuggestions, setShowAutoSuggestions] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState();

  const fetchProductClassifications = useCallback(
    debounce((query) => {
      if (query === null || query === undefined) {
        return null;
      }

      if (query === "") {
        return null;
      }

      if (query.length <= 2) {
        return null;
      }

      return fetch(`/api/int/search?q=${encodeURIComponent(query)}`, {
        method: "GET",
      })
        .then((r) => r.json())
        .then((r) => {
          setAutoSuggestions(r.data);
          setShowAutoSuggestions(r.data.length > 0);
          setIsFetching(false);
        })
        .catch(() => {
          setAutoSuggestions([]);
          setShowAutoSuggestions(false);
          setIsFetching(false);
        });
    }, INPUT_DELAY),
    []
  );

  const onChange = (event) => {
    setSearchKeyword(event.target.value);
    setIsFetching(true);
    setShowAutoSuggestions(false);
    setAutoSuggestions([]);

    fetchProductClassifications(event.target.value);
  };

  const onFocus = () => {
    setShowAutoSuggestions(true);
  };

  const onBlur = () => {
    setShowAutoSuggestions(false);
  };

  let inputKlass = "form-control form-control-lg";
  if (autoSuggestions.length > 0 && showAutoSuggestions) {
    inputKlass = `${inputKlass} autosuggest-input`;
  }

  const classificationSelected = (classification, newKeyword = "") => {
    handleClassificationChange(classification);

    setSearchKeyword(newKeyword.length > 0 ? newKeyword : searchKeyword);
    setSelectedKeyword(null);
    setShowAutoSuggestions(false);
  };

  const onKeywordSelect = (element) => {
    if (element.product_classifications.length === 1) {
      classificationSelected(element.product_classifications[0], element.label);
    } else {
      setSearchKeyword(element.label);
      setSelectedKeyword(element);
      setShowAutoSuggestions(false);
    }
  };

  const renderAutosuggestions = () => {
    if (showAutoSuggestions && !isFetching && autoSuggestions.length > 0) {
      return (
        <div className="list-group autosuggest-list">
          {autoSuggestions.map((element) => (
            <button
              type="button"
              key={element.id}
              onMouseDown={(e) => {
                e.preventDefault();
                onKeywordSelect(element);
              }}
              className="list-group-item list-group-item-action"
            >
              {element.label}
            </button>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderClassificationSelection = () => {
    if (!selectedKeyword) {
      return null;
    }
    return (
      <>
        <p className="mt-4 text-muted">
          Dutify found the following classifications for the selected{" "}
          <em>{searchKeyword}</em> keyword:
        </p>
        <div className="list-group">
          {selectedKeyword.product_classifications.map((classification) => (
            <button
              type="button"
              key={classification.path}
              onMouseDown={(e) => {
                e.preventDefault();
                classificationSelected(classification);
              }}
              className="list-group-item list-group-item-action"
            >
              <div className="text-sm lh-50">
                <span className="text-dark font-weight-bold">
                  {classification.name}
                </span>
              </div>
              <small className="d-block text-muted">
                {`in ${classification.path}`}
              </small>
            </button>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="form-group autosuggest">
      <input
        placeholder="Product name"
        name={`${railsFormName}[description]`}
        className={inputKlass}
        value={searchKeyword}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {renderAutosuggestions()}
      {renderClassificationSelection()}
      <hr className="divider divider-fade" />
    </div>
  );
}

export default ProductClassificationSearch;
