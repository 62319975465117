import React from "react";
import PropTypes from "prop-types";
import ProductClassificationSearch from "./ProductClassificationSearch";
import ProductClassificationSelect from "./ProductClassificationSelect";

class ProductClassification extends React.Component {
  constructor(props) {
    super(props);

    const { classificationTree, productDescription } = this.props;

    this.handleClassificationChange = this.handleClassificationChange.bind(this);
    this.toggleAutocomplete = this.toggleAutocomplete.bind(this);
    this.state = {
      selectedValue: "",
      productDescription: productDescription || "",
      hideInput: false,
      classificationTree,
    };
  }

  handleClassificationChange(selectedAutoSuggestion) {
    const { classificationTree } = this.state;

    if (selectedAutoSuggestion.ancestry_ids[0]) {
      classificationTree.selectedValue = selectedAutoSuggestion.ancestry_ids[0];
    }
    if (selectedAutoSuggestion.ancestry_ids[1]) {
      classificationTree.selectOptions[
        selectedAutoSuggestion.ancestry_ids[0]
      ].selectedValue = selectedAutoSuggestion.ancestry_ids[1];
    }

    if (selectedAutoSuggestion.ancestry_ids[2]) {
      classificationTree.selectOptions[
        selectedAutoSuggestion.ancestry_ids[0]
      ].selectOptions[selectedAutoSuggestion.ancestry_ids[1]].selectedValue =
        selectedAutoSuggestion.ancestry_ids[2];
    }

    if (selectedAutoSuggestion.ancestry_ids[3]) {
      classificationTree.selectOptions[
        selectedAutoSuggestion.ancestry_ids[0]
      ].selectOptions[selectedAutoSuggestion.ancestry_ids[1]].selectOptions[
        selectedAutoSuggestion.ancestry_ids[2]
      ].selectedValue = selectedAutoSuggestion.ancestry_ids[3];
    }

    this.setState((previousState) => ({
      ...previousState,
      hideInput: true,
      classificationTree,
    }));
  }

  toggleAutocomplete() {
    const { classificationTree } = this.state;

    classificationTree.selectedValue = "";

    this.setState((previousState) => ({
      ...previousState,
      hideInput: false,
      classificationTree,
    }));
  }

  render() {
    const { classificationTree, productDescription } = this.state;
    const { leafIndex, railsFormName } = this.props;

    return (
      <>
        <ProductClassificationSearch
          value={productDescription}
          handleClassificationChange={this.handleClassificationChange}
          railsFormName={railsFormName}
        />
        <ProductClassificationSelect
          classificationTree={classificationTree}
          railsFormName={railsFormName}
          leafIndex={leafIndex}
        />
      </>
    );
  }
}

ProductClassification.defaultProps = {
  classificationTree: {},
  productDescription: "",
  leafIndex: 0,
  railsFormName: "",
};

ProductClassification.propTypes = {
  classificationTree: PropTypes.object,
  leafIndex: PropTypes.number,
  railsFormName: PropTypes.string,
  productDescription: PropTypes.string,
};

export default ProductClassification;
